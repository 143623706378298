<template>
    <KCourse loader-class="MBcont" course-id="1" title="Анатомия головы" :items="items">
        
            <div class="MBtextcont">
                <p> Задача обрубовки:</p>
                <ul>
                    <li>показать все основные значимые точки лица</li>
                    <li>как направлены плоскости на лице</li>
                </ul>

                <p>
                    Начнем мы, как всегда, с изображения на бумаге, а затем перейдем к
                    пластилину. 
                </p>

                <h3>2.1 Обрубовка по фотографии</h3>                
            </div>

            <VideoView video-id="22951645287b4e3eb57992436f73746d"/>

            <div class="MBtextcont">
                <h3>2.2 Лепим обрубовку</h3>
            </div>

            <VideoView video-id="bfdd26f37b77433a808b0bc6ef2bc4f7"/>

            <div class="MBtextcont">
                <h3>2.3 Дорабатываем обрубовку</h3>
            </div>

            <VideoView video-id="f99226e419784a0487c2e765fc17ec76"/>

            <div class="MBtextcont">
                <h3>Ваша задача:</h3>
                <ol>
                    <li>
                        Найти и распечатать любое подходящее вам изображение лица и поделить
                        его на зоны.
                    </li>
                    <li>
                        А затем постараться применить эти знания на голове из пластилина, как
                        это сделано в уроке.
                    </li>
                </ol>
                <p>
                    На этом можно остановиться. То, что я делаю почти готовое личико — это
                    просто лирическое отступление для вдохновения, его делать не нужно.
                </p>
            </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Лепим череп", url:"/mycourses/head/1"},
                { title: "Делаем обрубовку", url:"/mycourses/head/2"},
                { title: "Лепим лицо", url:"/mycourses/head/3"},
                { title: "Основа из La Doll", url:"/mycourses/head/4"},
                { title: "Создаем объемы", url:"/mycourses/head/5"},
                { title: "Доработка деталей", url:"/mycourses/head/6"},
            ],
        }
        }
    }
</script>